import { useEffect, useState } from 'react';
import Translator from '../../classes/Translator/Translator';

const ExchangeLogic = () => {
  const { translator } = Translator();

  return {
    translator,
  };
};
export default ExchangeLogic;
