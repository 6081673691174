import { useContext, useEffect, useState } from 'react';
import Translator from '../../classes/Translator/Translator';
import GeneralContext from '../../context/GeneralContext';

const SignUpLogic = (callback, validate, props) => {
  const { setGeneralValues } = useContext(GeneralContext);
  const { translator } = Translator();
  const [values, setValues] = useState({
    name: '',
    lastname: '',
    email: '',
    password: '',
    repeatedPassword: '',
    showPassword: false,
    showRepeatedPassword: false,
    fullRegistration: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setGeneralValues((old_values) => ({ ...old_values, email: values.email }));
  };

  const handleCheckbox = (e) => {
    const _checked = e.target.checked;
    setChecked(_checked);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowRepeatedPassword = () => {
    setValues({
      ...values,
      showRepeatedPassword: !values.showRepeatedPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, callback, isSubmitting]);

  return {
    errors,
    values,
    setValues,
    setErrors,
    checked,
    handleChange,
    handleClickShowPassword,
    handleClickShowRepeatedPassword,
    handleMouseDownPassword,
    handleSubmit,
    handleCheckbox,
    translator,
  };
};

export default SignUpLogic;
