import { useState, useEffect } from 'react';
import Translator from '../../../classes/Translator/Translator';

const OrdersLogic = (orders) => {
  const { translator } = Translator();
  const [current, setCurrent] = useState(-1);
  const [values, setValues] = useState({
    ...orders,
  });
  const [children, setChildren] = useState([]);

  const setClass = () => {
    setValues({ ...values });
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orders.children) {
      setChildren(orders.children);
    }
  }, [orders]);

  const changeState = (panel) => (e, newValue) => {
    setCurrent(newValue ? panel : -1);
  };
  return {
    current,
    changeState,
    values,
    translator,
    children,
  };
};

export default OrdersLogic;
