import React, { useState } from 'react';
import { Card, Typography, CardContent, useTheme } from '@material-ui/core';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { OrdersLogic, useStyles, PropTypes } from './orders.module';

const Orders = (orders) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeFilter, setActiveFilter] = useState('all');
  const { current, changeState, values, translator } = OrdersLogic(orders);

  return (
    <Card
      className={
        theme.palette.mode === 'light' ? classes.root : classes.rootLight
      }
    >
      <CardContent className={classes.cardContainer}>
        <Accordion
          elevation={0}
          classes={{ root: classes.MuiAccordionroot }}
          expanded={current === 0}
          onChange={changeState(0)}
        >
          <AccordionSummary
            className={classes.root}
            expandIcon={
              <KeyboardArrowDownIcon
                className={classes.keyboardArrowDownIcon}
              />
            }
            aria-controls={values.ariaControls}
            classes={{ expanded: classes.expandedPanel }}
          >
            <Typography
              variant="h5"
              color="initial"
              align="left"
              paragraph
              className={classes.questions}
            >
              My currents orders
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography
              variant="body1"
              color="initial"
              align="left"
              className={classes.answers}
            >
              <ButtonGroup
                variant="contained"
                aria-label="contained primary button group"
                className={classes.customButtonGroup}
              >
                <Button
                  onClick={() => {}}
                  className={
                    activeFilter === 'all'
                      ? classes.customButtonBold
                      : classes.customButton
                  }
                >
                 ALL
                </Button>
                <Button
                  onClick={() => {}}
                  className={
                    activeFilter === 'buy'
                      ? classes.customButtonBold
                      : classes.customButton
                  }
                >
                  BUY
                </Button>
                <Button
                  onClick={() => {}}
                  className={
                    activeFilter === 'sell'
                      ? classes.customButtonBold
                      : classes.customButton
                  }
                >
                  SELL
                </Button>
              </ButtonGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};
Orders.propTypes = {
  ariaControls: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  questions: PropTypes.string,
  answers: PropTypes.string,
  answers1: PropTypes.string,
  answers2: PropTypes.string,
};

export default Orders;
