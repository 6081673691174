import Joi from 'joi';

const nameSchema = Joi.string().required();

const lastnameSchema = Joi.string().required();

const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required();

const pattern = '^(?=.*?[A-Z])(?=.*?[0-9])(?=[^\\d\\s]*\\d)(?=\\w*[\\W_])\\S{8,}$'

const passwordSchema = Joi.string().pattern(new RegExp(pattern, 'm')).required();

export default function validator(values) {
  const errors = {};
  const { password, repeatedPassword } = values;
  const { error: errorName } = nameSchema.validate(values.name);
  const { error: errorLastname } = lastnameSchema.validate(values.lastname);
  const { error: errorEmail } = emailSchema.validate(values.email);
  const { error: errorPassword } = passwordSchema.validate(values.password);

  if (errorName) {
    errors.error_name = 'Ingresa un nombre válido.';
  }

  if (errorLastname) {
    errors.error_lastname = 'Ingresa un apellido válido.';
  }

  if (errorEmail) {
    errors.error_email = 'Ingresa un email válido.';
  }

  if (errorPassword) {
    errors.error_password = 'Ingresa un password válido.';
  }

  if (
    !password ||
    !repeatedPassword ||
    password.localeCompare(repeatedPassword) !== 0
  ) {
    errors.error_repeated_password = 'Las contraseñas no coinciden.';
  }

  return errors;
}
