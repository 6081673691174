import React, {useState} from 'react';
import { Card, Typography, CardContent, useTheme } from '@material-ui/core';
import { useStyles, CardUsdLogic } from './cardUsd.module';
import ButtonDefault from '../button/button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DefaultTooltip from '../tooltips/toolTip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';


const CardUsd = (card) => {
  const classes = useStyles();
  const theme = useTheme();
  const { values, openDialog, setOpenDialog } = CardUsdLogic(card);
  const [activeFilter, setActiveFilter] = useState('transfer');


  return (
    <>
      <Card
        onClick={values.titleAmount ? null : () => setOpenDialog(true)}
        className={
          theme.palette.mode === 'light' ? classes.root : classes.rootLight
        }
      >
        {values.title ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <DefaultTooltip tooltip={values.tooltip} />
          </div>
        ) : null}
        <CardContent className={classes.cardContainer}>

          <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
          className={classes.customButtonGroup}
        >
          <Button
            onClick={() => {}}
            className={
              activeFilter === 'transfer'
                ? classes.customButtonBold
                : classes.customButton
            }
          >
            Transfer to buy
          </Button>
          <Button
            onClick={() => {}}
            className={
              activeFilter === 'withdraw'
                ? classes.customButtonBold
                : classes.customButton
            }
          >
            Withdraw USD
          </Button>
          
        </ButtonGroup>

          <Typography
            className={values.titleAmount ? classes.titleAmount : classes.title}
          >
            {values.title ? values.title : values.titleAmount} {values.amount}
          </Typography>
          <Typography className={classes.value}>{values.value}</Typography>
          <Typography className={classes.title}> Available {values.available} USD</Typography>
          
        </CardContent>
      </Card>
    </>
  );
};

export default CardUsd;
