import React from 'react';
import DMRVChart from '../dmrvChart/dmrvChart';

const Test = () => (
  <div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <DMRVChart></DMRVChart>
  </div>
);

export default Test;
