import * as React from 'react';
import useStyles from './tooltip.styles';
import InfoIcon from '@material-ui/icons/Info';
import { IconButton, Tooltip } from '@material-ui/core';

export default function DefaultTooltip(props) {
  const classes = useStyles();
  return (
    <Tooltip
      title={props.tooltip}
      placement="bottom-start"
      className={classes.tooltip}
    >
      <IconButton>
        <InfoIcon className={classes.infoIcon} />
      </IconButton>
    </Tooltip>
  );
}
