import React from 'react';
import { Badge, Fab, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Box, Grid } from '@material-ui/core';
import { UserLogic, UserService, useStyles } from './user.module';
import { Edit } from '@material-ui/icons';
// import Mati from '../../mati/mati';
import PersonIcon from '@material-ui/icons/Person';
const User = (props) => {
  const { uploadProfilePicture, getProfilePicture } = UserService();
  const { values, upload } = UserLogic(
    props,
    uploadProfilePicture,
    getProfilePicture
  );
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className={classes.containerUserAvatar}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={upload}
                  />
                  <Fab
                    color="primary"
                    size="small"
                    component="span"
                    aria-label="add"
                  >
                    <Edit />
                  </Fab>
                </label>
              }
            >
              <Avatar
                className={classes.avatar}
                variant="circular"
                src={values.user.profile_picture}
              />
            </Badge>
          </div>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className={classes.userData}>
            <Typography className={classes.userHello}>
              Account Holder:
            </Typography>
            <Typography className={classes.email}>
              {values.user.username}
            </Typography>
            <Typography className={classes.userHello}>Public Key:</Typography>
            <Typography className={classes.publicKey}>
              {values.user.publicKey}
            </Typography>
            <Typography className={classes.userHello}>Email:</Typography>
            <Typography className={classes.email}>
              {values.user.email}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default User;
