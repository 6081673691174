import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  root: {
      border: '2px solid #00DD9E',
      borderRadius: '20px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      transition: '0.3s',
      '&:hover': {
        transform: 'scale3d(1.05, 1.05, 1)',
        transition: '0.3s',
      },
      height: '100%',
    },
    rootLight: {
      border: '2px solid #00DD9E',
      borderRadius: '20px',
      transition: '0.3s',
      '&:hover': {
        transform: 'scale3d(1.05, 1.05, 1)',
        transition: '0.3s',
      },
      height: '100%',
    },
    cardContainer:{
      height: '100%',
      padding: '10px 0px 4px 0px !important' ,
  
    },
    value: {
      fontFamily: 'Montserrat',
      fontSize: '50px',
      fontWeight: '700',
      lineHeight: '88px',
      letterSpacing: '0em',
      textAlign: 'center',
      color: theme.palette.text.tertiary,
      marginBottom:10
  
    },
    title: {
      fontFamily: 'MontserratBold',
      fontSize: '30px',
      fontWeight: 500,
      lineHeight: '3px',
      letterSpacing: '0em',
      textAlign: 'center',
      color: theme.palette.text.tertiary,
      marginBottom:45,
      marginTop: 30
    },
    blockchain: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.02em',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
    marginTop: 20
  },
  underlineBlockchain: {
    borderBottom: '4px solid #00DD9E',
    paddingBottom: '2px',
    display: 'inline-block',

  },
  buyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,

  },
  buyButtonItem:{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'table-cell',
      verticalAlign: 'middle',
      marginLeft:3,
      marginRight:3
      },
  },

  buyBlockchain:{
    marginBottom: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleAmount:{
    fontFamily: 'MontserratMedium',
    // lineHeight: '35px',
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: '38px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
  },

  customButtonGroup: {
    width: '80%',
    boxShadow: 'none',
    borderRadius: '0px',
    border: 'none',
  },
  customButton: {
    flex: 1,
    margin: '0 0px 20px',
    fontFamily: 'MontserratBold',
    // fontFamily: 'MontserratExtraBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    backgroundColor: '#2CDD00',
    color: '#041370',
    fontSize: '18px',
    '&:hover': {
      backgroundColor: '#2CDD00',
      color: '041370',
    },
  },

}));
export default useStyles;
