import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import CardEgrnCoin from '../shared/cardEgrnCoin/cardEgrnCoin';
import CardUsd from '../shared/cardUsd/cardUsd';
import Orders from '../shared/orders/orders';

import Footer from '../footer/Footer';
import { useStyles, ExchangeLogic } from './exchange.module';

const Exchange = (props) => {
  const classes = useStyles();

  const { translator } = ExchangeLogic(props);

  return (
    <div className={classes.dashboardStyle}>
      <Container>
        {/* <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <User user={values.user} />
          </Grid>
        </Grid> */}
      </Container>

      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                Exchange
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Grid container spacing={2}>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CardEgrnCoin/>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CardUsd
                  tooltip={"buy usd"}
                  title={"Current USD"}
                  value={0.00}
                  available={0.00}            
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Orders/>
              </Grid>

              
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          // spacing={3}
          className={classes.coinSoon}
        ></Grid>
      </Container>
      <Footer />
    </div>
  );
};
export default Exchange;
