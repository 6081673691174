import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator'

const CardEgrnCoinLogic = (card) => {
  console.log(card)
  const { translator } = Translator();
  const [values, setValues] = useState({
    ...card,
  });
  // const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  async function handleOpen() {
    // setOpenModal(true);
  }

  async function handleClose() {
    console.log('handle Close in Card Dashboard');
    // setOpenModal(false);
  }
  const onChange = (open) => {
    // setOpenModal(open);
  };

  useEffect(() => {
    const values = {value: '0.00', available: '0.00'};
    setValues({ ...card, ...values });
    console.log(card)
  }, [card]);

  // useEffect(() => {
  //   console.log('openModal in useEffect', openModal);
  // }, [openModal]);

  return {
    values,
    onChange,
    handleOpen,
    handleClose,
    openDialog, 
    setOpenDialog,
    translator

  };
};

export default CardEgrnCoinLogic;
