import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { VisibilityOff, Visibility } from '@material-ui/icons';

import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Grid,
} from '@material-ui/core';

import ButtonDefault from '../shared/button/button';
import WelcomeBanner from '../shared/welcomeBanner/welcomeBanner';
import AlertMessage from '../shared/alert/alert';
import Footer from '../footer/Footer';
import CustomError from '../../classes/CustomError/CustomError';

import {
  SignUpService,
  SignUpLogic,
  useStyles,
  validator,
  UserBuilder,
} from './signUp.module';

export default function SignUp(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    handleChange,
    handleClickShowPassword,
    handleClickShowRepeatedPassword,
    handleMouseDownPassword,
    handleSubmit,
    handleCheckbox,
    values,
    errors,
    setValues,
    setErrors,
    checked,
    translator,
  } = SignUpLogic(() => signUp(), validator, props);

  async function signUp() {
    const { name, lastname, email, password } = values;
    const user = new UserBuilder()
      .setName(name)
      .setLastname(lastname)
      .setEmail(email)
      .setPassword(password)
      .buildToRegister();
    const response = await SignUpService().signUp(user);

    if (response instanceof CustomError) {
      setErrors({ ...errors, api_error: response.message });
    } else {
      setValues({ ...values, fullRegistration: true });
      history.push('/verify');
    }
  }

  const isValid =
    !values.name ||
    errors.error_name ||
    !values.lastname ||
    errors.error_lastname ||
    !values.email ||
    errors.error_email ||
    !values.password ||
    errors.error_password ||
    !values.repeatedPassword ||
    errors.error_repeated_password ||
    !checked;

  return (
    <div>
      <div className={classes.root} xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid className={classes.root}>
            <Grid item xs={6} md={6} className={classes.welcomeBanner}>
              <WelcomeBanner text={translator('signUp.welcome')} greet={translator('loginSection.welcome')} />
            </Grid>
            <Grid
              className={classes.registerSection}
              item
              xs={6}
              sm={12}
              md={6}
            >
              <h2 className={classes.title}>{translator('signUp.signUp')}</h2>
              <form
                className={classes.form}
                noValidate
                autoComplete={'off'}
                onSubmit={handleSubmit}
              >
                <label className={classes.labels}>
                  {translator('signUp.name')}
                </label>
                <OutlinedInput
                  className={classes.textField}
                  type="text"
                  value={values.name}
                  name={'name'}
                  onChange={handleChange}
                />
                {errors && errors.error_name && (
                  <AlertMessage type="error" text={errors.error_name} />
                )}

                <label className={classes.labels}>
                  {translator('signUp.lastname')}
                </label>
                <OutlinedInput
                  className={classes.textField}
                  type="text"
                  value={values.lastname}
                  name={'lastname'}
                  onChange={handleChange}
                />
                {errors && errors.error_lastname && (
                  <AlertMessage type="error" text={errors.error_lastname} />
                )}

                <label className={classes.labels}>
                  {translator('signUp.mail')}
                </label>
                <OutlinedInput
                  className={classes.textField}
                  type="email"
                  value={values.email}
                  name={'email'}
                  onChange={handleChange}
                />
                {errors && errors.error_email && (
                  <AlertMessage type="error" text={errors.error_email} />
                )}

                <label className={classes.labels}>
                  {translator('signUp.password')}
                </label>
                <FormControl className={classes.passwordField}>
                  <OutlinedInput
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    name={'password'}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className={classes.icon}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                <p className={classes.passwordHelper}>
                  {translator('signUp.securityPassword')}
                </p>
                {errors && errors.error_password && (
                  <AlertMessage type="error" text={errors.error_password} />
                )}

                <label className={classes.labels}>
                  {translator('signUp.confirmPassword')}
                </label>
                <FormControl className={classes.passwordField}>
                  <OutlinedInput
                    type={values.showRepeatedPassword ? 'text' : 'password'}
                    value={values.repeatedPassword}
                    name={'repeatedPassword'}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRepeatedPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className={classes.icon}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                <p className={classes.passwordHelper}>
                  {translator('signUp.securityPassword')}
                </p>
                {errors && errors.error_repeated_password && (
                  <AlertMessage
                    type="error"
                    text={errors.error_repeated_password}
                  />
                )}

                <div className={classes.termsContainer}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckbox}
                  />
                  <p className={classes.termsText}>
                    {translator('signUp.terms1')}{' '}
                    <strong className={classes.boldText}>
                      {translator('signUp.terms2')}
                    </strong>
                    {translator('signUp.terms3')}{' '}
                    <strong className={classes.boldText}>
                      {translator('signUp.terms4')}
                    </strong>
                  </p>
                </div>

                <ButtonDefault
                  type="blue"
                  text={translator('signUp.register')}
                  isSubmit={true}
                  disabled={isValid}
                />
                {values.fullRegistration && (
                  <AlertMessage
                    type="success"
                    text={translator('signUp.registrationCompleted')}
                  />
                )}
                {errors && errors.api_error && (
                  <AlertMessage type="error" text={errors.api_error} />
                )}
              </form>
              <p className={classes.singUpOption}>
                {translator('signUp.alreadyRegistered')}
                <Link className={classes.singUpLink} to="/login">
                  <strong>{translator('signUp.login')}</strong>
                </Link>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
