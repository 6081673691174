import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  Typography,
  Container,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import {
  LoginSectionLogic,
  LoginSectionService,
  useStyles,
  validator,
} from './loginSection.module';
import CustomError from '../../classes/CustomError/CustomError';
import ButtonDefault from '../shared/button/button';
import AlertMessage from '../shared/alert/alert';
import Footer from '../footer/Footer';

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { translator } = LoginSectionLogic();
  const {
    handleChange,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleSubmit,
    createSharedSession,
    values,
    errors,
    setValues,
    setErrors,
    pathAfterLogin,
  } = LoginSectionLogic(
    () => login(),
    validator,
    props,
    LoginSectionService().createSession
  );

  async function login() {
    const response = await LoginSectionService().login({
      client_id: values.client_id,
      password: values.password,
    });

    if (response instanceof CustomError) {
      if (response.status === 428) {
        history.push('/verify');
      } else {
        setErrors({ ...errors, api_error: response.message });
      }
    } else {
      setValues({ ...values, showSuccess: true });
      await createSharedSession(response);
      history.push(pathAfterLogin);
      if(props.reload){
        window.location.reload(true);
      }
    }
  }

  return (
    <>
      <div className={classes.bannerContainer}>
        <Container maxWidth="md">
          <div className={classes.bannerContent}>
            <h1 className={classes.title}>{translator('loginSection.welcome')}</h1>
            <div className={classes.containerSubtitle}>
              <h2 className={classes.subtitle}>
                How nice to hear from you again. Every day we are more people in
                eGreen
              </h2>
            </div>
            <h3 className={classes.login}>Login</h3>

            <Container maxWidth="sm">
              <form onSubmit={handleSubmit}>
                <Grid container align="center" spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.containerLabels}>
                      <label htmlFor="client_id" className={classes.labels}>
                        {translator('loginSection.mailLabel')}
                      </label>
                    </div>
                    <OutlinedInput
                      className={classes.textField}
                      id="client_id"
                      type="email"
                      name={'client_id'}
                      value={values.client_id}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {errors && errors.error_email && (
                      <AlertMessage type="error" text={errors.error_email} />
                    )}
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.containerLabels}>
                      <label htmlFor="password" className={classes.labels}>
                        {translator('loginSection.passwordLabel')}
                      </label>
                    </div>
                    <OutlinedInput
                      className={classes.textFieldPassword}
                      id="password"
                      type={values.showPassword ? 'text' : 'password'}
                      name={'password'}
                      value={values.password}
                      onChange={handleChange}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            style={{ color: '#FFFFFF' }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {errors && errors.error_password && (
                      <AlertMessage type="error" text={errors.error_password} />
                    )}
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.buttonLogin}>
                      <ButtonDefault
                        type="buttonGreen"
                        text={translator('loginSection.button')}
                        isSubmit={true}
                      />
                      {values.showSuccess && (
                        <AlertMessage
                          text={'Inicio de sesión exitoso'}
                          type={'success'}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {errors && errors.api_error && (
                      <AlertMessage text={errors.api_error} type={'error'} />
                    )}
                  </Grid>
                </Grid>
              </form>
              <Link to="/restore" className={classes.loginLink}>
                <Typography className={classes.forgot}>
                  {translator('loginSection.restorePassword')}
                </Typography>
              </Link>
              <Typography className={classes.account}>
                {translator('loginSection.signupOption')}
                <Link className={classes.loginLink} to="/signup">
                  <u className={classes.signUp}>
                    {translator('loginSection.signupLink')}
                  </u>
                </Link>
              </Typography>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Login;
